var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page h-100"},[_c('van-nav-bar',{attrs:{"title":"个人资料","left-arrow":""},on:{"click-left":_vm.onClickLeft}}),_c('div',{staticClass:"user-info-content"},[_c('div',{staticClass:"upload-img flex align-center flex-column justify-center"},[_c('van-uploader',{key:_vm.updateKey,attrs:{"max-size":100 * 1024,"before-read":_vm.beforeRead,"after-read":_vm.submit},on:{"oversize":_vm.onOversize}},[_c('span',{staticClass:"avatar bg-center bg-no-repeat bg-size-cover border-50 d-inline-block",style:({
                    backgroundImage: `url(${_vm.showLogo})`
                })})]),_c('span',{staticClass:"font-12 font-500 text-primary m-t-10"},[_vm._v("点击更换头像")])],1),_c('van-cell',{key:_vm.nickname,attrs:{"title":"昵称"},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('van-field',{staticClass:"p-l-10 text-right nick-input p-r-0 text-primary",attrs:{"name":"昵称","placeholder":"请输入昵称"},model:{value:(_vm.form.nickname),callback:function ($$v) {_vm.$set(_vm.form, "nickname", $$v)},expression:"form.nickname"}})]},proxy:true}])}),_c('van-cell',{key:`gender-${_vm.gender}`,attrs:{"title":"性别"},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('span',{staticClass:"text-primary",on:{"click":_vm.showPopup}},[_vm._v(_vm._s(_vm.form.gender))])]},proxy:true}])}),_c('van-cell',{key:`sign-${_vm.sign}`,attrs:{"title":"个人简介"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('van-field',{staticClass:"p-l-10 w-100 text-primary sign p-b-10 font-400",attrs:{"name":"个人简介","placeholder":"请输入简介","maxlength":"15"},model:{value:(_vm.form.sign),callback:function ($$v) {_vm.$set(_vm.form, "sign", $$v)},expression:"form.sign"}})]},proxy:true}])}),_c('div',{key:_vm.label,staticClass:"label-cell p-l-15 p-r-15 flex justify-between align-center"},[_c('span',{staticClass:"text-white font-500",staticStyle:{"font-size":"14px"}},[_vm._v("标签")]),_c('div',{staticClass:"label-box flex align-center justify-center p-relative"},[_c('div',{class:{'click-bg': _vm.showLabelOptions}},[_c('span',{staticClass:"title text-center font-12 d-inline-block bg-no-repeat bg-center",class:{
                                'is-host': _vm.showLabel === '11',
                                'is-expert': _vm.showLabel === '12',
                                'is-fan' : _vm.showLabel === '1'
                            },staticStyle:{"font-size":"14px"},on:{"click":function($event){_vm.showLabelOptions = !_vm.showLabelOptions}}}),_c('icon-png',{attrs:{"icon":_vm.showLabelOptions ? 'my/top' : 'my/down',"width":10,"height":10}})],1),(_vm.labels)?_c('ul',{staticClass:"options flex flex-column justify-around",class:{
                        'is-expand': _vm.showLabelOptions
                    }},_vm._l((_vm.labels),function(option){return _c('li',{key:option.id,staticClass:"item-icon bg-no-repeat bg-center",class:{
                                'is-host': option.label === '11',
                                'is-expert': option.label === '12',
                                'is-fan' : option.label === '1'
                            },on:{"click":function($event){return _vm.selectLabel(option)}}})}),0):_vm._e()]),_c('div',{staticClass:"border-bottom height-half"})]),_c('div',{staticClass:"w-100 text-center m-t-80"},[_c('ConfirmButton',{staticClass:"submit-btn font-14 p-t-10 p-b-10 font-medium font-400 text-color",attrs:{"title":"确认保存"},nativeOn:{"click":function($event){return _vm.save.apply(null, arguments)}}})],1)],1),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('van-picker',{attrs:{"title":"性别","show-toolbar":"","columns":_vm.columns},on:{"confirm":_vm.onConfirm,"cancel":_vm.closePopup}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }